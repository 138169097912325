










































































import { Component, Mixins } from 'vue-property-decorator';
import SKBodyMixin from './SKBody.mixin';

@Component
export default class SKBodyFrontFemale extends Mixins(SKBodyMixin) {}
